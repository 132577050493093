import * as R from 'ramda'
import { t } from 'i18next'
import { getCommercialTermChoiceTranslation } from '../../../../../common/translations'
import {
  CommercialTerms,
  ClientFrontlineConfig,
  FrontlineConfigJson,
  Opportunity,
  Offer,
  EquipmentGroups
} from '../../../../../types/types'
import { CommercialTermRow } from '../../../../../backend/db/tables'
import { Option } from 'react-select'
import * as Moment from 'moment'
import { CommercialTermKey, EquipmentDate } from 'nemo-pricing'
import { getEquipmentWiseDate, getEquipmentContractDate } from '../../../../../common/common-utils'
import { CurrentOffer } from '../../../state/current-offer'
import { FrontlineConfig } from '../../../state/frontline-config'
import { isRenegoOpp } from '../../../../../common/opportunity'
import { setOfferPriceEstimation } from '../../../../../client/js/nemo/offer/price-estimation'

export function isPaired(typeOrKey: string): boolean {
  return typeOrKey === 'pair-select' || typeOrKey.includes(':')
}

function pair(val1: string, val2: string): string {
  return val1 + ':' + val2
}

export function isKcsmEscalationDateFlexible(frontlineConfig: ClientFrontlineConfig) {
  return frontlineConfig.commercialTerms.some(
    term => term.key === 'KCSM_BASE_MONTH_ESCALATION' && term.type === 'flexible-date'
  )
}

export function isKcsmEscalationDateEnabled(frontlineConfig: ClientFrontlineConfig) {
  return frontlineConfig.commercialTerms.some(term => term.key === 'KCSM_BASE_MONTH_ESCALATION')
}

export function getEscalationFlowCommercialTerms(commercialTerms: CommercialTermRow[]) {
  const escalationFlowCommercialTermKeys = [
    'VBEGDAT',
    'VABNDAT',
    'VAT',
    'VAT_2',
    'VAT_3',
    'KONDA',
    'VLAUFK',
    'PROPORTIONAL_PRICE'
  ]
  return commercialTerms.filter(commercialTerm => {
    const { key } = commercialTerm
    return escalationFlowCommercialTermKeys.includes(key)
  })
}

export function getEscalationDateOption(frontlineConfig: ClientFrontlineConfig): '1' | '2' {
  return frontlineConfig.sap.escalationDate || '1'
}

export function getEscalationStartDate(frontlineConfig: ClientFrontlineConfig, contractStartDate: string) {
  if (contractStartDate === 'flexible') {
    return isKcsmEscalationDateFlexible(frontlineConfig)
      ? 'flexible'
      : isKcsmEscalationDateEnabled(frontlineConfig)
      ? undefined
      : 'flexible'
  }
  const escalationDateOption = getEscalationDateOption(frontlineConfig)
  return escalationDateOptions[escalationDateOption](contractStartDate)
}

export function getEscalationEquipmentStartDate(
  frontlineConfig: ClientFrontlineConfig,
  contractStartDate: EquipmentDate,
  offer: Offer
) {
  const eqContractDate = {}
  offer.buildings.forEach(({ details }) => {
    details.equipment.forEach(equipment => {
      const escalationDateOption = getEscalationDateOption(frontlineConfig)
      const equipmentStartDate = getEquipmentWiseDate(equipment.salesToolId, contractStartDate) || ''
      Object.assign(eqContractDate, {
        [equipment.salesToolId]: escalationDateOptions[escalationDateOption](equipmentStartDate)
      })
    })
  })
  return eqContractDate
}

interface EscalationDateOptions {
  '1': (contractStartDate: string) => string
  '2': (contractStartDate: string) => string
}

const escalationDateOptions: EscalationDateOptions = {
  '1': (contractStartDate: string) => Moment(contractStartDate).startOf('year').add(1, 'year').format('YYYY-MM-DD'),
  '2': (contractStartDate: string) => Moment(contractStartDate).add(1, 'year').format('YYYY-MM-DD')
}

export function getValue(
  commercialTerm: CommercialTermRow,
  currentOfferCommercialTerms: CommercialTerms,
  isTermPaired: boolean,
  isContractStartDateAtEquipmentLevel: boolean
): string | string[] {
  const { key, pair: pairKey } = commercialTerm
  const first = currentOfferCommercialTerms[key]!
  const second = currentOfferCommercialTerms[pairKey as CommercialTermKey]!

  if (['VBEGDAT', 'VENDDAT', 'VABNDAT'].includes(key) && isContractStartDateAtEquipmentLevel) {
    return getEquipmentContractDate(first as EquipmentDate, key) as string
  } else if (key === 'KCSM_BASE_MONTH_ESCALATION' && isContractStartDateAtEquipmentLevel) {
    return ''
  }

  return isTermPaired ? pair(first as string, second as string) : (first as string | string[]) // changes
}

export function unpair(pairTerm: string): CommercialTermKey[] {
  return pairTerm ? (pairTerm.split(':') as CommercialTermKey[]) : []
}

export const getChoicei18n = getCommercialTermChoiceTranslation(t)

export function getKey(commercialTerm: CommercialTermRow, isTermPaired: boolean) {
  const { key, pair: pairKey } = commercialTerm
  return isTermPaired && pairKey ? pair(key, pairKey) : key
}

export function getOptions(
  commercialTerm: CommercialTermRow,
  isTermPaired: boolean,
  autoSelectSalesOffice: boolean,
  branchOffice: string
): Option[] | undefined {
  const { key, values, pair: pairKey } = commercialTerm
  if (R.isNil(values)) {
    return undefined
  } else if (isTermPaired) {
    const pairedValues = values as Array<{ key: string; value: string }>
    let dropdownValues = pairedValues
    if (autoSelectSalesOffice) {
      const matchingPairedValues = pairedValues.filter(option => branchOffice === option.key)
      if (matchingPairedValues.length > 0) {
        dropdownValues = matchingPairedValues
      }
    }
    return dropdownValues.map(({ key: value, value: pairValue }: { key: string; value: string }) => {
      const label = getChoicei18n(key, value) || value
      const pairLabel = getChoicei18n(pairKey!, pairValue) || pairValue
      return {
        label: `${pairLabel} (${label})`,
        value: pair(value, pairValue)
      }
    })
  } else {
    const plainValues = values as string[]
    return plainValues.map((value: string) => ({
      label: getChoicei18n(key, value) || value,
      value
    }))
  }
}
export function getValuesOnContractStartDateChange(
  frontlineConfig: ClientFrontlineConfig,
  changedValue: string | undefined
) {
  if (!changedValue) {
    return {
      contractStartDate: undefined,
      escalationDate: undefined
    }
  }
  const escalationDate = getEscalationStartDate(frontlineConfig, changedValue)
  return {
    contractStartDate: changedValue,
    escalationDate
  }
}

export function getEscalationDateOnChange(
  frontlineConfig: ClientFrontlineConfig,
  changedValue: string | undefined,
  currentContractStartDate: string | undefined
) {
  if (!changedValue) {
    if (!currentContractStartDate) {
      return
    }
    return getEscalationStartDate(frontlineConfig, currentContractStartDate)
  }
  return changedValue
}

export function resetCommercialTerms(
  frontlineConfig: FrontlineConfigJson,
  defaultCommercialTerms: CommercialTerms,
  opportunity: Opportunity,
  isRefresh: boolean
) {
  const { branchOffice, contractSpecificAgreement, opportunityCategory } = opportunity
  const { autoSelectSalesOffice, commercialTerms } = frontlineConfig
  const vkburCommercialTerm = commercialTerms.find((ct: CommercialTermRow) => ct.key === 'VKBUR')
  const augruCommercialTermValue = commercialTerms.find((ct: CommercialTermRow) => ct.key === 'AUGRU')?.values
  if (autoSelectSalesOffice && branchOffice && vkburCommercialTerm && vkburCommercialTerm.type === 'pair-select') {
    const options = vkburCommercialTerm.values as Array<{ key: string; value: string }>
    const matchingOptions = options.filter(option => branchOffice === option.key)
    if (matchingOptions.length === 1) {
      defaultCommercialTerms.VKBUR = matchingOptions[0].key
      defaultCommercialTerms.VKGRP = matchingOptions[0].value
    }
  }

  const renegoDefaultAtEqLevel = typeof defaultCommercialTerms.AUGRU === 'object' && isRefresh

  if (
    isRenegoOpp(opportunityCategory) &&
    augruCommercialTermValue &&
    augruCommercialTermValue.includes('320') &&
    !renegoDefaultAtEqLevel
  ) {
    defaultCommercialTerms.AUGRU = '320'
  }

  if (isContractAgreementEnabled(frontlineConfig.commercialTerms)) {
    defaultCommercialTerms.VBAK_BNAME = contractSpecificAgreement
  }
  return defaultCommercialTerms
}

const isContractAgreementEnabled = (commercialTermsConfig: CommercialTermRow[]) =>
  commercialTermsConfig.find(terms => terms.key === 'VBAK_BNAME')

export const multiSelectCommercialTerms = ['KCSM_INV_ATTACH']

export const isMultiSelectCommercialTerm = (key: string) => multiSelectCommercialTerms.includes(key)

export const notApplicableValues = ['00']

export const getFullValForMultiSelect = (fullVal: string | string[]) => {
  if (Array.isArray(fullVal) && fullVal[fullVal.length - 1] === notApplicableValues[0]) {
    fullVal = notApplicableValues
  } else if (Array.isArray(fullVal) && fullVal[0] === notApplicableValues[0]) {
    fullVal.shift()
  }
  return fullVal
}

export const validateContractDates = (contractEndDateKey: CommercialTermKey | '') => {
  const offer = CurrentOffer.get()
  const commercialTerms = offer.commercialTerms
  const equipmentCount = offer.buildings.reduce((acc, building) => acc + building.details.equipment.length, 0)

  const contractStartDates = Object.values((commercialTerms.VBEGDAT as EquipmentDate) || {}).filter(v => v != null)
  const contractEndDates = contractEndDateKey
    ? Object.values((commercialTerms[contractEndDateKey] as EquipmentDate) || {}).filter(v => v != null)
    : []

  const uniqueContractStartDates = contractStartDates.filter(
    (value: string, index: number, array: string[]) => array.indexOf(value) === index
  )
  const uniqueEndStartDates = contractEndDates.filter(
    (value: string, index: number, array: string[]) => array.indexOf(value) === index
  )

  const isAllContractDatesSet = contractEndDateKey
    ? contractStartDates.length === equipmentCount && contractEndDates.length === equipmentCount
    : contractStartDates.length === equipmentCount
  return (
    !isAllContractDatesSet ||
    !(contractEndDateKey
      ? uniqueContractStartDates.length === 1 && uniqueEndStartDates.length === 1
      : uniqueContractStartDates.length === 1)
  )
}

const setCommercialTermDates =
  (key: CommercialTermKey, value: string | string[] | undefined, equipmentIDs: string[]) =>
  (offer: Offer): Offer => {
    const commercialTermValue: EquipmentDate = offer.commercialTerms[key] as EquipmentDate
    const updatedCommericalTermValue = equipmentIDs.reduce(
      (ctv, equipmentId) =>
        ({
          ...ctv,
          [equipmentId]: value
        } as EquipmentDate),
      commercialTermValue
    )

    return {
      ...offer,
      commercialTerms: {
        ...offer.commercialTerms,
        [key]: updatedCommericalTermValue
      }
    }
  }

export const hasCommercialTermPriceConfigured = (commercialTerms: CommercialTermRow[], key: keyof CommercialTerms) =>
  commercialTerms.some(ct => ct.key === key && ct.has_pricing_impact)

const setCommercialTermOrderReasonBillingPlan =
  (key: CommercialTermKey, value: string | undefined, equipmentIDs: string[]) =>
  (offer: Offer): Offer => {
    const commercialTermValue: EquipmentDate =
      typeof offer.commercialTerms[key] === 'object' ? (offer.commercialTerms[key] as EquipmentDate) : {}
    const { commercialTerms } = FrontlineConfig.get()
    const updatedCommericalTermValue = equipmentIDs.reduce(
      (ctv, equipmentId) => ({
        ...ctv,
        [equipmentId]: value
      }),
      commercialTermValue
    )

    return {
      ...offer,
      priceEstimation: hasCommercialTermPriceConfigured(commercialTerms, key) ? undefined : offer.priceEstimation,
      commercialTerms: {
        ...offer.commercialTerms,
        [key]: updatedCommericalTermValue
      }
    }
  }

export const setContractStartDate = (date: string | null, salesToolId: string[]) => {
  const frontlineConfig = FrontlineConfig.get()
  const isEscalationDateConfigured =
    frontlineConfig.commercialTerms.filter(ct => ct.key === 'KCSM_BASE_MONTH_ESCALATION').length === 1

  if (isEscalationDateConfigured) {
    const { contractStartDate, escalationDate } =
      date !== null
        ? getValuesOnContractStartDateChange(frontlineConfig, date)
        : {
            contractStartDate: undefined,
            escalationDate: undefined
          }

    CurrentOffer.update(
      R.pipe(
        setCommercialTermDates('VBEGDAT', contractStartDate, salesToolId),
        setCommercialTermDates('KCSM_BASE_MONTH_ESCALATION', escalationDate, salesToolId)
      )
    )
  } else {
    CurrentOffer.update(R.pipe(setCommercialTermDates('VBEGDAT', date ? date : undefined, salesToolId)))
  }
}

export const setContractEndDate = (
  date: string | null,
  salesToolId: string[],
  contractEndDateKey: CommercialTermKey
) => {
  CurrentOffer.update(
    R.pipe(setCommercialTermDates(contractEndDateKey as CommercialTermKey, date as string, salesToolId))
  )
}

export const setBillingOrOrderReason = (value: string | null, salesToolId: string[], key: CommercialTermKey) => {
  CurrentOffer.update(
    R.pipe(
      setCommercialTermOrderReasonBillingPlan(key as CommercialTermKey, value as string, salesToolId),
      setOfferPriceEstimation
    )
  )
}

export const getIsBillingModeApplicable = (equipmentGroups: EquipmentGroups): boolean => {
  const { offerSelections } = FrontlineConfig.get()
  const groupOfferSelections: any = offerSelections.group
  const keysSet: Set<string> = new Set()
  ;['elevator', 'escalator', 'door'].forEach(rootKey => {
    if (groupOfferSelections[rootKey]) {
      groupOfferSelections[rootKey].forEach((entry: any) => {
        if (entry.module === 'value_added_services' && entry.key) {
          keysSet.add(entry.key)
        }
      })
    }
  })
  const vasServiceKeys = Array.from(keysSet)

  const isValidValue = (value: any): boolean =>
    value !== undefined &&
    value !== null &&
    value !== '0' &&
    value !== 'NA' &&
    !(Array.isArray(value) && value.length === 1 && value[0] === '0')

  const getValidVasSelections = (selections: any): string[] =>
    Object.keys(selections).reduce((vasSelections: any, key: string) => {
      if (vasServiceKeys.includes(key) && isValidValue(selections[key])) {
        return vasSelections.concat(key)
      }
      return vasSelections
    }, [])

  return Object.values(equipmentGroups).reduce((isFilteredObjEmpty, equipmentGroup) => {
    if (equipmentGroup.groupKind === 'elevators_and_escalators') {
      const elevatorSelections = equipmentGroup.elevator ? equipmentGroup.elevator?.normal?.selections : {}
      const escalatorSelections = equipmentGroup.escalator ? equipmentGroup.escalator?.normal?.selections : {}
      const validElevatorVasSelections = getValidVasSelections(elevatorSelections)
      const validEscalatorVasSelections = getValidVasSelections(escalatorSelections)
      if (validElevatorVasSelections.length || validEscalatorVasSelections.length) {
        return true
      }
    } else {
      const doorSelections = equipmentGroup.door ? equipmentGroup.door?.normal?.selections : {}
      const validDoorVasSelections = getValidVasSelections(doorSelections)
      if (validDoorVasSelections.length) {
        return true
      }
    }
    return isFilteredObjEmpty
  }, false)
}

export const isKCSM_BILLING_PLAN = (key: string) => key === 'KCSM_BILLING_PLAN'

export const isAUGRU = (key: string) => key === 'AUGRU'
