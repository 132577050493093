import * as moment from 'moment'
import * as Ru from '../../../common/ramda-utils'
import { CommercialTerms, Offer } from '../../../types/types'
import { CommercialTermRow } from '../../../backend/db/tables'
import { CommercialTermKey } from 'nemo-pricing'
import {
  getEscalationFlowCommercialTerms,
  getIsBillingModeApplicable,
  isMultiSelectCommercialTerm
} from '../components/OfferWizard/CommercialTerms/CommercialTermsUtils'

const minDate = moment().subtract(1, 'year').startOf('year').subtract(1, 'day')
const maxDate = moment([2201, 1, 1])

export const maxLength = 30

export function parseDate(date: string): moment.Moment {
  return moment(date, 'YYYY-MM-DD')
}

function isValidDate(date: moment.Moment) {
  return moment.isMoment(date) && date.isValid() && date.isAfter(minDate) && date.isBefore(maxDate)
}

export function isAfter(date: moment.Moment, maybeStartDate: moment.Moment) {
  return isValidDate(maybeStartDate) ? date.isAfter(maybeStartDate) : true
}

function isBefore(date: moment.Moment, maybeEndDate: moment.Moment) {
  return isValidDate(maybeEndDate) ? date.isBefore(maybeEndDate) : true
}

function validateDate(currentOfferCommercialTerms: CommercialTerms, key: CommercialTermKey, value: string) {
  const startDate = parseDate(currentOfferCommercialTerms.VBEGDAT! as string)
  const endDate = parseDate(currentOfferCommercialTerms.VENDDAT! as string)
  const acceptanceDate = parseDate(currentOfferCommercialTerms.VABNDAT! as string)

  switch (key) {
    case 'VENDDAT':
      return isValidDate(endDate) && isAfter(endDate, startDate)
    case 'VABNDAT':
      return isValidDate(acceptanceDate) && isAfter(acceptanceDate, startDate)
    case 'KCSM_BASE_MONTH_ESCALATION':
      const escalationDate = parseDate(value)
      return isValidDate(escalationDate) && isAfter(escalationDate, startDate) && isBefore(escalationDate, endDate)
    default:
      return isValidDate(parseDate(value))
  }
}

export const validateMultiSelectCommercialTerm = (
  commercialTerm: CommercialTermRow,
  currentOfferCommercialTerms: CommercialTerms,
  validateOptional: boolean
) => {
  const key = commercialTerm.key
  const optional = commercialTerm.optional
  const value = currentOfferCommercialTerms[key]

  if (validateOptional && Ru.isNilOrEmpty(value)) {
    return optional
  }
  return !(!commercialTerm.is_multiselect && Array.isArray(value) && value.length > 1) // ? false : true
}

export const validateCommercialTerm = (
  currentOfferCommercialTerms: CommercialTerms,
  commercialTerm: CommercialTermRow,
  isContractStartDateAtEquipmentLevel: boolean,
  isBillingModeApplied: boolean,
  isBillingPlanAtEquipmentLevel?: boolean,
  isOrderReasonAtEquipmentLevel?: boolean
): boolean => {
  const key = commercialTerm.key
  const optional = commercialTerm.optional
  const value = currentOfferCommercialTerms[key]

  switch (true) {
    case ['VBEGDAT', 'KCSM_BASE_MONTH_ESCALATION', 'VENDDAT', 'PROPORTIONAL_PRICE', 'VABNDAT', 'KONDA'].includes(key) &&
      isContractStartDateAtEquipmentLevel:
      return true

    case key === 'KCSM_BILLING_MODE' && !isBillingModeApplied:
      return true

    case key === 'KCSM_BILLING_PLAN' && isBillingPlanAtEquipmentLevel:
      return true

    case key === 'AUGRU' && isOrderReasonAtEquipmentLevel:
      return true

    case value == null:
      return optional
  }

  switch (commercialTerm.type) {
    case 'date':
      return validateDate(currentOfferCommercialTerms, key, value as string)
    case 'flexible-date':
      return (value && value === 'flexible') || validateDate(currentOfferCommercialTerms, key, value as string)
    case 'text':
      return value ? (value.length as number) <= maxLength : false
    default:
      return isMultiSelectCommercialTerm(key)
        ? validateMultiSelectCommercialTerm(commercialTerm, currentOfferCommercialTerms, true)
        : !Ru.isNilOrEmpty(value)
  }
}

export const areCommercialTermsValid =
  (commercialTermRow: CommercialTermRow[], isEscalationFlow: boolean = false) =>
  (offer: Offer) => {
    // We are filtering commercial terms for escalation flow.
    const filteredCommercialTerms = isEscalationFlow
      ? getEscalationFlowCommercialTerms(commercialTermRow)
      : commercialTermRow
    // const answers = offer.commercialTerms
    const {
      isContractStartDateAtEquipmentLevel,
      isBillingPlanAtEquipmentLevel,
      isOrderReasonAtEquipmentLevel,
      equipmentGroups,
      commercialTerms
    } = offer
    const isBillingModeApplied = getIsBillingModeApplicable(equipmentGroups)
    const results = filteredCommercialTerms.map(term =>
      validateCommercialTerm(
        commercialTerms,
        term,
        isContractStartDateAtEquipmentLevel,
        isBillingModeApplied,
        isBillingPlanAtEquipmentLevel,
        isOrderReasonAtEquipmentLevel
      )
    )
    return results.every(r => r)
  }
