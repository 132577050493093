import * as ServiceWorker from '../service-worker/client'
import * as Bowser from 'bowser'
import * as Bugsnag from '../bugsnag'
import * as Task from 'data.task'
import { isRtlLang } from 'rtl-detect'
import { version } from '../../../common/version.json'
import { parallel } from '../../../common/async'
import { trackTiming } from '../analytics-tracker'
import { checkAuth, getData, getServerTimeFor, HttpClientErrorHolder } from '../ajax/ui'
import { AjaxError, HttpClientError, NetworkError, ClockError } from '../ajax/http-client'
import { handleError } from '../ui-thread'
import { ApiMasterData } from '../../../types/backend-api'
import { ClockDriftData } from '../../../types/types'

window.version = version

console.info('--- CLIENT BOOTSTRAP', version, '---')

const MAX_ALLOWED_CLOCK_DRIFT = 20000

const loadScript = (url: string, callback?: (event?: any) => void) => {
  const head = document.getElementsByTagName('head')[0]
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url
  if (callback !== undefined) {
    script.onload = () => callback()
    script.onerror = errorEvent => callback(errorEvent)
  }
  head.appendChild(script)
}
const inProgressClass = 'in-progress'
const createProgressEl = (name: string) => {
  const $el = document.createElement('li')
  $el.innerHTML = name
  $el.classList.add(name, 'animation', inProgressClass)
  return $el
}

const getElement = (selector: string) => document.querySelector(selector) || document.createElement('div')

const $server = getElement('.server')
$server.classList.remove(inProgressClass)
const $loadProgress = getElement('.load-progress')
const $authenticationData = createProgressEl('authentication-data')
const $applicationData = createProgressEl('application-data')
const $dynamicData = createProgressEl('dynamic-data')
const $applicationLogic = createProgressEl('application-logic')
const $serverVersion = getElement('.server-version')
const $clientVersion = getElement('.client-version')
const $userInfo = getElement('.user-info')
const $body = getElement('body')

$clientVersion.innerHTML = `client version: ${version}`
const loadData = (): Task<{ data?: ApiMasterData; error?: AjaxError | NetworkError | ClockError }> => {
  $loadProgress.appendChild($dynamicData)

  return new Task<{ data?: ApiMasterData; error?: AjaxError | NetworkError | ClockError }>((reject, resolve) => {
    const startTime = new Date().getTime()
    getData().fork(
      (httpClientErrorHolder: HttpClientErrorHolder) => {
        const isNetworkError = (e: HttpClientError) => e instanceof NetworkError
        // when app is stopped, we get response with 503 (AWS) for all urls
        const isAppDown = (e: HttpClientError) => e instanceof AjaxError && (e.status === 403 || e.status === 503)

        if (isNetworkError(httpClientErrorHolder.err) || isAppDown(httpClientErrorHolder.err)) {
          // we are offline, this is ok
          $dynamicData.classList.add('not-available')
          resolve({ error: httpClientErrorHolder.err as NetworkError })
        } else {
          reject(httpClientErrorHolder)
        }
      },
      data => {
        trackTiming('Auth time', undefined, new Date().getTime() - startTime)
        resolve({ data })
      }
    )
  })
}

const loadAppData = (): Task<Record<any, any>> =>
  new Task((reject, resolve) => {
    loadScript('/js/app.js', error => {
      if (error) {
        $applicationData.classList.add('not-available')
        Bugsnag.notify(new Error('app.js error'), { metaData: error })
        reject({ message: 'app.js load error', name: 'load error' })
      } else {
        $applicationData.classList.remove(inProgressClass)
        resolve({})
      }
    })
  })

const checkBrowserTime = (): Task<Record<any, any>> =>
  new Task((__, resolve) => {
    getServerTimeFor(() => Date.now()).fork(
      _ => {
        console.info('Clock Drift Data is not available due to network error.')
        resolve({})
      },
      (data: ClockDriftData) => {
        console.info('ClockDriftData', data)
        if (Math.sqrt(Math.pow(data.browserClockDrift, 2)) > MAX_ALLOWED_CLOCK_DRIFT) {
          const msg =
            'Please check your devices system time. ' +
            `Clock drift is more than ${
              data.browserClockDrift / 1000
            } seconds comparing to Coordinated Universal Time (UTC).`
          console.warn(msg)
          // TODO, By rejecting we start to get lot of errors in production. Disable it for now.
          // return reject(new ClockError(msg, data))
        }
        resolve({})
      }
    )
  })

const ensureSupportedBrowser = () => {
  const supportedChrome = () => Bowser.chrome && parseInt(`${Bowser.version}`, 10) >= 67

  const supportedSafari = () => Bowser.safari && parseInt(`${Bowser.version}`, 10) >= 12

  const supportedFirefox = () => Bowser.firefox && parseInt(`${Bowser.version}`, 10) >= 66

  return new Task((reject, resolve) => {
    const isSupportedPlatform = supportedChrome() || supportedSafari() || supportedFirefox()
    if (!isSupportedPlatform) {
      const notification = {
        name: '',
        message:
          'Invalid browser: Please note that only the latest Google Chrome, Firefox and Safari is supported. ' +
          `Current browser: ${Bowser.name} ${Bowser.version}`
      }
      return reject(notification)
    }
    resolve({})
  })
}

ensureSupportedBrowser()
  .map(() => $loadProgress.appendChild($authenticationData))
  .chain(checkAuth)
  .map(data => {
    console.info('authenticated', data)
    $authenticationData.classList.remove(inProgressClass)
    $loadProgress.appendChild($applicationData)
    $userInfo.innerHTML = `${data.displayName} (${data.role}, ${data.salesOrganization})`
    $serverVersion.innerHTML = `server version: ${data.appVersion}`

    if (location.search.startsWith('?weinre=')) {
      loadScript(`${location.search.replace('?weinre=', '')}/target/target-script-min.js#anonymous`)
    }
  })
  .chain(_ => parallel([loadData(), loadAppData(), checkBrowserTime()]))
  .fork(
    handleError,
    ([{ data, error }]: Array<{ data?: ApiMasterData; error?: AjaxError | NetworkError | ClockError }>) => {
      $loadProgress.appendChild($applicationLogic)

      if (data && isRtlLang(data.settings.language)) {
        $body.setAttribute('dir', 'rtl')
      }
      window.onDataReceived($dynamicData, error, data)
      try {
        ServiceWorker.init()
      } catch (e) {
        console.error('Service worker init error: ' + JSON.stringify(e))
      }
    }
  )
